// src/components/DownloadButton.js
import React from "react"

const DownloadButton = () => {
  const handleDownload = () => {
    const fileUrl = "/20230719_ReembolsoRelatorio_de_despesas.xlsx"
    const link = document.createElement("a")
    link.href = fileUrl
    link.download = "20230719_ReembolsoRelatorio_de_despesas.xlsx"
    document.body.appendChild(link)
    link.click();
    document.body.removeChild(link)
  };

  return (
    <button onClick={handleDownload}>
      <span>Baixar</span> planilha para solicitação de reembolso
    </button>
  );
};

export default DownloadButton
