/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import DownloadButton from "@/components/DownloadButton"

import { 
  contentPage,
}  from '../assets/styles/Other.styles'

const PagamentoContent = () => {
  return (
    <Fragment>
      <section css={contentPage}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <DownloadButton />
              {/* <div className="text-center"><a href="#"><span>Baixar</span> planilha para solicitação de reembolso</a></div> */}
              <iframe src="https://forms.monday.com/forms/embed/01dd3d444d8bf38c33580685574acdce?r=use1" style={{ marginTop: 32, width: '100%', height: 1850, border: 'none' }} />
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default PagamentoContent;
